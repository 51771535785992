export let appName = "Grace Divine"
export const storeURL = "https://www.prettycharm.app"
export const apkDownloadLink = "https://drive.google.com/uc?export=download&id=1FZEZMSm15A0TDiiYhqL83pH9clSZeFlT"

// website data
// perfect piece

export const perfectPieceData = {
    headlineMsg: "Discover Perfect Piece, a simple to use jewelry app designed to bring you stunning, high-quality fashion jewelry at prices that fit your budget. Whether you're looking for elegant necklaces, chic earrings, or timeless pieces, our exclusive jewelry collections are curated by world-class designers to help you shine in every moment",
    headline: "Find the Perfect Piece",
    appTitle: "Perfect Piece"
}
