import { css } from "lit";

export const styles = css`

    app-website-simple-app-bar {

        --app-bar-background: white;
        border-bottom: 1px solid #f5f5f5;
          --download-btn-bg-color: #D2B48C;
          position: sticky;
          top: 0;
          z-index: 100;
    }

    app-website-simple-app-bar::part(app-title) {
        font-weight: 700;
        font-size: 1.5rem;
        font-family: var(--font-family);
    }
`